@import "../../../index.scss";

div.card-container {
  width: 18em;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  padding: 0;
  height: 27em;
  border: 1px solid transparent;
  background-color: transparent;
  color: $secondary-color;
  text-align: center;
  -webkit-perspective: 700px;
  perspective: 700px;

  .flip-card-description-container {
    justify-content: flex-start;
    height: 52%;
    margin-bottom: auto;

    h1 {
      color: lighten($secondary-color, 15%);
      font-size: 2rem;
      font-weight: 200;
      padding: 0.1em;
      margin: 0;
    }

    p {
      font-size: 1rem;
      font-weight: 200;
    }
  }

  .flip-card-image-container {
    height: 40%;

    img {
      margin: auto;
      max-height: 10em;
      max-width: 10em;
    }
  }

  span {
    margin-top: auto;
    align-self: flex-end;
    color: lighten($secondary-color, 15%);
    padding-bottom: 1px;
    font-weight: 400;

    i {
      color: $main-color;
      animation: blink 2s ease-in-out infinite;
    }
  }

  @keyframes blink {
    0% {
      opacity: 50%;
    }

    50% {
      opacity: 100%;
    }

    100% {
      opacity: 50%;
    }
  }

  .card-face.back {
    display: flex;
    flex-direction: column;
    .flip-card-image-container {
      height: 30%;
      margin: 0;
    }

    .flip-card-description-container {
      margin: 0;
      padding: 0;
      height: 65%;
    }

    img {
      margin: auto;
      max-height: 8em;
      max-width: 8em;
    }

    p {
      font-size: 0.8rem;
      text-align: justify;
      overflow: hidden;
      text-overflow: ellipsis;
      text-indent: 20px;
    }

    a {
      background-color: $tertiary-color;
      color: $main-color;
      border-radius: 5px;
      text-decoration: none;
      padding: 1em;
      font-weight: 400;
      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        background-color: $main-color;
        color: $tertiary-color;
      }
    }
  }

  /* gestion flip animation hover */
  .card {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: transform 0.6s ease-out;
    perspective: 700px;
    -ms-perspective: 700px;
    -webkit-perspective: 700px;
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    cursor: pointer;

    .card-face {
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      background: #9c9c9c;
      background: -webkit-linear-gradient(bottom, #9c9c9c, #ffffff);
      background: -moz-linear-gradient(bottom, #9c9c9c, #ffffff);
      background: linear-gradient(to top, #9c9c9c, #ffffff);
      backface-visibility: hidden;
    }

    .front,
    .back {
      padding: 5%;
      height: 90%;
      width: 90%;
    }

    .back {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
    }
  }

  /* trick pour éviter l'effet de bord sur mobile
  Sur certains nav mobile, le onclick de react trigger le hover.
  Alors on sélectionne uniquement les appareils avec pointer.
  On override l'init pour le non flip*/
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .card.not-flipped {
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);
      }
    }
  }

  /* Gestion du click pour version mobile */
  .card.is-flipped {
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
  }
  /* init pour quand la carte n'est pas flip */
  .card.not-flipped {
    transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
  }
}
