@import "../../../index.scss";

.carousel-item {
  height: 100%;

  .carousel-item-part {
    background-color: rgba(#2a3237, 1);
  }
}

.flex-container.row-flex.reverse {
  flex-direction: row-reverse;

  .carousel-item-part.img-part {
    .img-container {
      filter: drop-shadow(-8px 0 8px $tertiary-color);

      img {
        clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
      }
    }
  }
}

.carousel-item-part.img-part {
  flex: 1;

  .img-container {
    filter: drop-shadow(0 0 8px $tertiary-color);
    flex-grow: 1;
    text-align: left;

    img {
      clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
      height: auto;
      width: 100%;
    }
  }
}

.text-part {
  flex: 1;

  h2 {
    color: $tertiary-color;
    margin-top: 2em;
    margin-bottom: 0;
  }

  h3 {
    color: lighten($main-color, 25%);
  }

  p {
    line-height: 1.4rem;
    margin: 1em 3em 0 3em;
    font-size: 1rem;
    text-align: justify;
    text-indent: 2rem;
    color: $tertiary-color;
  }
}

@media screen and (max-width: 820px) {
  .carousel-item.flex-container.row-flex {
    .carousel-item-part.img-part {
      .img-container {
        text-align: center;
        img {
          height: auto;
          width: auto;
          max-height: 300px;
        }
      }
    }
  }
  .carousel-item.flex-container.row-flex.reverse {
    .carousel-item-part {
      max-width: 100vw;
    }

    .carousel-item-part.img-part {
      .img-container {
        filter: drop-shadow(0 0 0 transparent);
        img {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
      }
    }
  }

  .carousel-item.flex-container.row-flex {
    flex-direction: column;

    .img-part {
      .img-container {
        filter: drop-shadow(0 0 0 transparent);

        img {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
      }
    }

    .carousel-item-part.text-part {
      height: 100%;

      h2 {
        margin-top: 1em;
      }

      p {
        font-size: 0.8rem;
        margin: 1em 1em 3em 1em;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .text-part {
    h3 {
      margin-top: 0;
    }

    p {
      line-height: inherit;
      margin: 1.2em;
      font-size: 1rem;
      text-align: justify;
      text-indent: 2rem;
      color: $tertiary-color;
    }
  }
}

@media screen and (min-width: 901px) {
  .flex-container {
    .reverse {
      .text-part {
        padding: 0 0 0 3em;
      }
    }

    .text-part {
      padding: 0 3em 0 0;
    }
  }
}
