@import "../../../../index.scss";

.sub-card-container .sub-card.white-colored {
  margin: 0.8em 0;
  padding: 0.8em 0;
  z-index: -1;
  flex-grow: 1;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: space-around;
  align-items: center;

  filter: opacity(100%);
  h2 {
    color: $secondary-color;
    text-align: center;
  }
  p {
    color: $secondary-color;
    text-align: center;
    text-indent: 1rem;
  }
  .sub-card:last-child {
    &::after {
      border: none;
    }
  }
}
.sub-card {
  &::after {
    position: absolute;
    top: -30px;
    left: 10px;
    content: "";
    height: 25px;
    width: 5px;
    z-index: -10;
    border-left: 3px dashed rgba($tertiary-color, 0.5);
  }
}
.sub-card:nth-child(1) {
  margin-top: 0em;
}
