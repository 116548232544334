@import "../../index.scss";

.presentation-container {
  padding: 0;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1rem;
    text-align: center;

    b {
      color: $main-color;
    }
  }

  .presentation-illustrations {
    padding: 3vh 0;
    width: 100%;
    justify-content: space-between;
    .france-map {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 3vh 0;
      margin-right: 10em;
      i {
        z-index: 500;
        color: $main-color;
        font-size: 3.5rem;
        height: 80%;
        width: 100%;
        position: absolute;
        left: 20px;
        top: -15px;
        transition: all 0.3s ease;

        &:hover {
          filter: drop-shadow(3px 3px 2px $secondary-color);
          transform: scale(1.01);
        }
      }
    }

    .presentation-details {
      background-image: url("../../images//imac.jpg");
      background-size: cover;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      padding: 1em;

      p {
        color: $tertiary-color;
        margin: 1em;
        flex-grow: 1;
        font-size: 1rem;
        padding: 1.5em;
        text-align: center;
        align-items: center;
      }

      div {
        a,
        span {
          font-size: 1rem;
          text-decoration: none;
          border-radius: 5px;
          margin: 5px;
          cursor: pointer;
        }
      }
      .linkedin-presentation-button {
        &:hover {
          background-color: #0073b1;
          color: white;
        }
      }
    }
  }
}

/* version mobile */
@media screen and (max-width: 600px) {
  .presentation-container.flex-container.column-flex {
    padding: 0 5vw;

    .presentation-illustrations.flex-container.row-flex {
      flex-direction: column;
      justify-content: center;

      .france-map {
        margin: 0;
        padding: 0;
      }
    }

    .presentation-details {
      p {
        margin: 1.5em auto;
      }

      div {
        a {
          font-size: 1rem;
        }
      }
    }
  }
}

/* version tablette et petits écrans */
@media screen and (max-width: 1400px) {
  .presentation-container.flex-container.column-flex {
    padding: 0 2vw;

    .presentation-illustrations.flex-container.row-flex {
      .france-map {
        margin-right: 1vw;
        padding: 0;

        i {
          left: 20px;
          top: -35px;
        }
      }
    }
  }
}
