@import "../../index.scss";

#equipe {
  justify-content: center;
  align-items: center;
  width: 100%;

  .carousel-container {
    height: auto;
    width: 100%;
    margin: 3em 0 5em 0;
    box-shadow: 2px 2px 6px $tertiary-color--dark;
  }
}

@media screen and (max-width: 1300px) {
  #equipe {
    .carousel-container {
      width: 100vw;
      margin: 3em 0;
    }
  }
}
