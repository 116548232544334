@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
@import "../../index.scss";

body {
  font-family: "Roboto", "Helvetica Neue", "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  overflow-x: hidden;
  color: #2a3237;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Global flex */
/** Class réutilisables pour la mise en place du flex rapidement */
.global-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
}

/* Container flex */
.flex-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
}

/* se combine à flex-container pour affichage en ligne */
.row-flex {
  flex-direction: row;
}

/* se combine à flex-container pour affichage en colonne */
.column-flex {
  flex-direction: column;
}

.main-content-container-list {
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
}

.dark-block {
  background-color: $secondary-color !important;
}

/* Colorise le background des éléments pairs(2n) de la liste */
.main-content-container-list > li.block:nth-child(2n) {
  clip-path: polygon(0 0, 100% 7%, 100% 100%, 0 100%);
  // background: #2a3237;
  // background-image: linear-gradient(to bottom, #898989, #a5a5a5, #c2c2c2, #e0e0e0, #ffffff);
}

/* Colorise le background des éléments impairs(2n+1) de la liste */
.main-content-container-list > li:nth-child(2n + 1) {
  background-color: white;
}

/** Responsives blocks */

.block {
  justify-content: space-around;
  padding: 3vw 15vw;
  background-color: #2a3237;
}

/** layout */
.header-container {
  justify-content: flex-start;
  display: flex;
}

/** scroll to top button */
.layer-button-container {
  position: fixed;
  bottom: 4vh;
  right: 3vw;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1vw;
  z-index: 1;
}

.layer-button {
  padding: 20px;
  margin-top: 0.5em;
  margin-right: 0.5em;
  border-radius: 50%;
  color: $main-color;
  font-size: 1.7rem;
  z-index: 499;
  font-weight: lighter;
  border: 1px solid transparent;
  background-color: rgba($secondary-color--light, 0.7);
  transition: all 0.2s;
  box-shadow: 2px 2px 2px $secondary-color;

  i {
    text-align: center;
    width: 2rem;
  }

  &:hover {
    background-color: white;
    color: $main-color;
    cursor: pointer;
    box-shadow: 4px 4px 4px $secondary-color;
  }
}

.displayed {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

/* Bouton basique blanc */
.button--white {
  background: $tertiary-color;
  color: $main-color;
  text-align: center;
  padding: 15px;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: $main-color;
    color: $tertiary-color;
  }
}

/* Bouton basique rouge */
.button--red {
  background: $main-color;
  color: $tertiary-color;
  text-align: center;
  padding: 15px;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: $tertiary-color;
    color: $main-color;
  }
}

/* Container dans lequel on peut placer plusieurs éléments. Au survol de l'un d'entre eux,
les autres deviennent plus transparent tandis que le hover s'agrandit. */
.button-fading-container:hover > * {
  opacity: 0.5;
}

.button-fading-container:hover > *:hover {
  opacity: 1;
  transform: scale(1.05);
}

@media screen and (max-width: 600px) {
  .main-content-container-list > li.block:nth-child(2n) {
    clip-path: polygon(0 0, 100% 2%, 100% 100%, 0 100%);
  }

  .block {
    padding: 1vw;
  }

  .layer-button {
    padding: 15px;
    font-size: 1.5rem;
  }
}
