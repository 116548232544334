/* Footer */
@import "../../index.scss";

$sidepadding: 20vw;
$fontsize: 2.1rem;

footer.flex-container.column-flex {
  padding: 1vh $sidepadding 2vh $sidepadding;
}

footer {
  justify-content: space-around;
  width: 100- ($sidepadding * 2);
  margin: 0;
  background: $secondary-color;
  -webkit-background-size: cover;
  background-size: cover;
  flex-grow: 1;
  flex-wrap: wrap;
  color: $tertiary-color;

  .row-flex {
    justify-content: space-around;
    align-items: center;
    padding: 1vh;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  .address-container {
    flex-grow: 1;

    p {
      line-height: 0.7rem;
      font-size: 0.7rem;
      font-weight: lighter;

      &:first-child {
        font-weight: 400;
        line-height: 1rem;
        font-size: 1.1rem;
      }
    }
  }

  .brand-container {
    padding: 1vh 0;
    justify-content: flex-start;

    img {
      width: $fontsize * 2;
    }

    .brand-title {
      justify-content: flex-start;
      flex-direction: column;
      text-align: left;

      p {
        padding-left: 0.8rem;

        &:first-child {
          font-weight: bold;
          font-size: 1.2rem;
          line-height: 1px;
        }

        &:last-child {
          font-size: 0.8rem;
          line-height: 1px;
          text-transform: none;
          letter-spacing: normal;
          font-weight: lighter;
        }
      }
    }
  }

  .laws-container {
    display: flex;
    justify-content: flex-end;
    font-size: 0.7rem;
    font-weight: lighter;
  }

  .contact-row {
    justify-content: flex-start;

    .button--red {
      padding: 10px 40px;
      text-decoration: none;
      border-radius: 15px;
    }
  }

  .social-container {
    justify-content: flex-end;
    padding: 5vh 0;

    a,
    span {
      width: $fontsize;
      height: $fontsize;
      font-size: $fontsize;
      border-radius: 5px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      i {
        vertical-align: top;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  footer.flex-container.column-flex {
    padding: 0;
  }

  footer {
    justify-content: center;
    width: 100%;

    .row-flex {
      justify-content: center;
    }

    .social-container {
      .button--red {
        $size: 1.8rem;
        width: $size;
        height: $size;
        font-size: $size;
      }
    }
  }
}
