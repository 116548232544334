@import "../../../index.scss";

$card-main-color: $main-color;
$card-text-color: $secondary-color;
$card-border-radius: 7px;
$card-transition-time: 0.3s;
$card-padding: 1em;

.showed-true.desc-card-container {
  .main-card-container {
    filter: none;
  }
}
.desc-card-container {
  .main-card-container {
    box-shadow: 3px 3px 7px $sub-color;
    justify-content: space-between;
    text-align: center;
    transition: all 0.3s;
    border-radius: $card-border-radius;
    padding: 0;
    flex-grow: 1;
    transition: all 0.3s;
    &:hover {
      background-color: lighten($tertiary-color, 20%);
      box-shadow: 5px 5px 7px 5px $sub-color;
      filter: opacity(100%);

      i,
      h4,
      span {
        text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4);
      }
    }
  }

  div {
    justify-content: space-between;
  }

  i {
    padding: 0.8rem;
    font-size: 3.5rem;
    text-shadow: 3px 3px 2px rgba(152, 150, 150, 0.3);
    transition: all 0.5s;
    color: $card-text-color;
  }
  .desc-card-img {
    height: 6rem;
  }

  h4 {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    transition: all 0.3s;
    color: $card-text-color;
    margin: 0;
  }

  p {
    font-size: 1rem;
    color: lighten($secondary-color, 5%);
    transition: 0.5s ease-in;
    font-family: Calibri;
    margin: 0;
    letter-spacing: 0.03rem;
    min-height: 120px;
  }

  span {
    color: $card-text-color;
    font-size: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.2s;
    cursor: pointer;

    i {
      font-size: 1rem;
      transition: 0.2s;
    }

    &:hover > i {
      color: lighten($card-text-color, 30%);
      margin-left: 0.2rem;
    }
  }
}

/** Gestion des différentes couleurs  */
.sub-card-container,
.desc-card-container {
  .white-colored {
    filter: opacity(88%);
    $card-main-color: $main-color;
    padding: $card-padding;
    border-radius: $card-border-radius;
    transition: $card-transition-time;
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 246, 246, 1) 47%,
      rgba(237, 237, 237, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 1)),
      color-stop(47%, rgba(246, 246, 246, 1)),
      color-stop(100%, rgba(237, 237, 237, 1))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 246, 246, 1) 47%,
      rgba(237, 237, 237, 1) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 246, 246, 1) 47%,
      rgba(237, 237, 237, 1) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 246, 246, 1) 47%,
      rgba(237, 237, 237, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 246, 246, 1) 47%,
      rgba(237, 237, 237, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed', GradientType=1 );
  }
}
