#partenariat {
    justify-content: space-around;
    width: 100%;
    margin: 0;
}

.cards-container {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1vw;
}