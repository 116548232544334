@import '../../index.scss';

$box-shadow: 2px 2px 6px $secondary-color;
$box-shadow--light: 2px 2px 6px $tertiary-color--dark;

.modal-container {
    $margin: 80px;
    position: fixed;
    right: $margin + 10px;
    bottom: $margin;
    z-index: 1000;
    min-width: 35vw;
    box-shadow: $box-shadow;
    background: $secondary-color--light;
    border-radius: 20px;
    transition: all 0.5s ease-in;
    gap: 1em;
    font-family: 'Calibri';

    .loader {
        border: 16px solid #f3f3f3;
        border-top: 16px solid $main-color;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        margin: auto;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .button-close {
        $margin: 10px;
        font-size: 1rem;
        color: $main-color;
        position: absolute;
        right: $margin;
        top: $margin;
        border: 1px solid transparent;
        border-radius: 50%;
        padding: 0.6rem 0.8rem;
        background: $secondary-color--light;
        box-shadow: $box-shadow;
        transition: all 0.4s;
        text-align: center;

        &:hover {
            border: 1px solid $main-color;
            color: $main-color;
            background-color: $tertiary-color;
            cursor: pointer;
        }
    }

    div {
        margin: 1em 0 1.5em 0;
        width: 100%;
        text-align: center;

        .reussite {
            color: $green;
        }

        .echec {
            color: darken($gold, 20%)
        }

        h1 {
            color: $main-color;
            font-family: 'Calibri';
            font-weight: 400;
            font-size: 1.9rem;
        }

        form {
            margin: 0.5em 1.5em;

            .flex-container.row-flex.form-block {
                gap: 2em;
                flex-wrap: wrap;
                margin-bottom: 0;

                span {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    text-align: center;
                    color: lighten($secondary-color, 20%);
                }
            }

            .form-block {
                justify-content: center;
                flex-grow: 1;

                label {
                    margin-bottom: 5px;
                    color: $secondary-color;
                }

                .error-form {
                    padding-top: 5px;
                    color: lighten($main-color, 20%);
                }

                input,
                textarea {
                    font-family: 'Calibri';
                    font-size: 0.9rem;
                    border: none;
                    color: lighten($secondary-color, 15%);
                    background-color: $tertiary-color;
                    border-radius: 5px;
                    box-shadow: $box-shadow--light;
                    padding: 8px;
                    resize: none;
                    transition: 0.4s ease-in-out;

                    &:hover,
                    &:focus {
                        color: black;
                        box-shadow: 2px 2px 10px $tertiary-color--dark;
                    }
                }

                button {
                    border-radius: 25px;
                    border: none;
                    padding: 5px;
                    font-size: 0.9rem;
                    flex-grow: 1;
                    line-height: 2rem;
                    box-shadow: $box-shadow--light;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        background-color: $tertiary-color;
                        color: black;
                        box-shadow: $box-shadow;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    .modal-container {
        left: 50px;
        right: 50px;
    }
}

@media screen and (max-width: 600px) {
    .modal-container {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
    }

    div {
        form {
            margin: 0.5em;
        }
    }
}