@import "../../../index.scss";

.flex-container.customer-card {
  $squareSize: 9em;
  $transitionTime: 0.3s;
  margin: 5px;
  height: $squareSize;
  width: $squareSize;
  border: 1px solid lighten($secondary-color, 70%);
  border-radius: 5px;
  transition: all $transitionTime;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;

  .customer-link {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-decoration: none;
    font-size: 1rem;
    text-shadow: 1px 1px 2px white;
    font-family: Calibri;
    color: $sub-color;
    transform: translate(-50%, -50%);
    transition: all $transitionTime;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(#{$squareSize} - 2em);
    height: auto;
    max-height: 4.5em;
    max-width: calc(#{$squareSize} - 2em);
    width: auto;
    transition: all $transitionTime;
  }

  &:hover {
    box-shadow: 2px 2px 8px lighten($secondary-color, 50%);
    .customer-link {
      opacity: 1;
    }

    img {
      filter: blur(0.2em);
    }
  }
}

/* version tablette et petits écrans */
@media screen and (max-width: 600px) {
  .flex-container.customer-card {
    $squareSize: 6em;
    $transitionTime: 0.3s;
    margin: 8px;
    height: $squareSize;
    width: $squareSize;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: calc(#{$squareSize} - 2em);
      height: auto;
      max-height: 4.5em;
      max-width: calc(#{$squareSize} - 2em);
      width: auto;
      transition: all $transitionTime;
    }
  }
}
