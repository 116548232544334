@import "../../index.scss";

.services-block {
  padding: 5vh 0;
  transition: all 0.5s;

  ul {
    width: 100%;
    padding: 1em 0;
    margin: 0;
    li.sub-service-container {
      vertical-align: top;
      display: inline;
      width: 31%;
      margin-right: 4%;
      padding: 0.5em 0;
      &:last-child {
        margin-right: 0;
      }
      .desc-card-container {
        display: inline-block;
        width: 100%;
      }
    }
  }
}

/* version mobile */
@media screen and (max-width: 600px) {
  .services-block {
    padding: 6vh 0;
  }
}

/* version tablette et petits écrans */
@media screen and (max-width: 1400px) {
  .services-block {
    padding: 5vh 0;
    ul {
      li.sub-service-container {
        .desc-card-container {
          .main-card-container {
            p {
              height: 160px;
            }
          }
        }
      }
    }
  }
}

/* version tablette et petits écrans */
@media screen and (max-width: 1172px) {
  .services-block {
    ul {
      display: inline;
      li.sub-service-container {
        vertical-align: top;
        margin: 0;
        padding: 0.5em 0;
        &:last-child {
          margin-right: 0;
        }
        .desc-card-container {
          width: 100%;
          margin: 1em 0;
          .main-card-container {
            p {
              height: auto;
            }
          }
        }
      }
    }
  }
}
