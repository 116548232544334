$main-color: #cc0000;
$secondary-color: #292b33;
$secondary-color--light: #f3f3f3;
$tertiary-color: #ffffff;
$tertiary-color--dark: #9e9e9e;
$sub-color: #161718;
$gold: #cc5c00;
$blue: #007a7a;
$green: #006900;

body {
  font-family: "Roboto", "Helvetica Neue", "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  overflow-x: hidden;
  color: $secondary-color;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $tertiary-color;
  transition: all 0.3s ease;
}

a:hover {
  color: $main-color;
}

.cut-bottom {
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
}
