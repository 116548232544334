@import "../../../index.scss";

.dark {
  h3 {
    color: lighten($main-color, 25%);
  }

  h1 {
    color: $tertiary-color;
  }
}

.light {
  h3 {
    color: $main-color;
  }

  h1 {
    color: $secondary-color;
  }
}

h1,
h3 {
  text-align: center;
}

h3 {
  font-size: 0.8rem;
  line-height: 2.4rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 0;
  margin-top: 5em;
  margin-bottom: 0;
}

h1 {
  font-size: 3.2rem;
  margin-top: 0;
  padding: 0;
  margin-bottom: 1rem;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 0.6rem;
  }
}
