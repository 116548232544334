@import "../../index.scss";

/* Navigation Bar (Navbar)  */
nav.navbar.white {
  padding-top: 0;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  padding-bottom: 0;

  .navbar-brand img {
    -webkit-filter: grayscale(0) invert(0);
    filter: grayscale(0) invert(0);
  }
}

nav.navbar.white a {
  color: #444;
}

nav.navbar {
  $light-shadow: rgba(rgb(36, 36, 36), 0.25);
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 15vw;
  width: 100vw;
  background-color: $light-shadow;
  height: 80px;
  position: fixed;
  opacity: 1;
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
}

.navbar a.navbar-brand {
  height: 100%;
  padding-top: 8px;
}

.navbar a.navbar-link {
  padding-top: 27px;
}

.navbar-brand img {
  height: 8vw;
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
  max-height: 7vh;
  transition: all 0.4s;

  &:hover {
    -webkit-filter: grayscale(0) invert(0);
    filter: grayscale(0) invert(0);
  }
}

nav.navbar {
  a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 17px;

    &:hover {
      transform: scale(1.1);
      color: lighten($main-color, 20%);
    }
  }

  .navbar-link.active {
    color: $main-color;
  }
}


.navbar .icon {
  font-size: 24px;
  display: none;
}

@media screen and (max-width: 600px) {
  .navbar a:not(:first-child) {
    display: none;
  }

  .navbar a.icon {
    float: right;
    padding-right: 6vw;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  nav.navbar {
    width: 100vw;
    position: fixed;
    padding: 0;
    height: auto;
  }

  .navbar-brand img {
    padding: 0;
    height: 8vh;
  }

  nav.navbar.responsive .navbar-brand {
    height: 5vh;
    padding-bottom: 50px;
  }

  nav.navbar.responsive {
    position: fixed;
    height: 100%;
    background-color: #d4d4d4;
    z-index: 5;
    opacity: 1;
    justify-content: flex-start;
    flex-direction: column;

    .navbar-brand img {
      height: 10vh;
      -webkit-filter: grayscale(0) invert(0);
      filter: grayscale(0) invert(0);
      transition: all 0.4s;
    }
  }

  .navbar.responsive .icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  nav.navbar.responsive a {
    color: #444;
    z-index: 100;
    float: none;
    display: block;
    text-align: left;

    &:hover {
      transform: translateX(10px);
      background-color: darken($tertiary-color, 50%);
    }
  }
}

@media screen and (max-width: 770px) {
  nav.navbar {
    padding-left: 1vw;
  }
}