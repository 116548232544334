@import "../../index.scss";

.flex-container.row-flex.customer-cards-grid {
  flex-wrap: wrap;
  padding: 2vw 0 5vw 0;
  gap: 3em;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}
